import React, { useState, useEffect } from 'react';
import { ArrowLeft, Save } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { isAuthenticated } from '../lib/auth';

type TrackingCode = {
  id: string;
  name: string;
  code: string;
  is_active: boolean;
  location: 'head' | 'body';
  updated_at: string;
};

export function TrackingCodeManagement() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [trackingCodes, setTrackingCodes] = useState<TrackingCode[]>([]);
  const [editedCodes, setEditedCodes] = useState<TrackingCode[]>([]);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/profile');
      return;
    }
    fetchTrackingCodes();
  }, [navigate]);

  async function fetchTrackingCodes() {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('tracking_codes')
        .select('*')
        .order('name');

      if (error) throw error;
      
      setTrackingCodes(data || []);
      setEditedCodes(data || []);
    } catch (error) {
      console.error('Error fetching tracking codes:', error);
      setError('Failed to load tracking codes');
    } finally {
      setLoading(false);
    }
  }

  async function handleSaveAll() {
    try {
      setSaving(true);
      setError(null);

      const changedCodes = editedCodes.filter(edited => {
        const original = trackingCodes.find(c => c.id === edited.id);
        return original && (
          original.code !== edited.code ||
          original.is_active !== edited.is_active ||
          original.location !== edited.location
        );
      });

      if (changedCodes.length === 0) {
        return;
      }

      // Update each code individually to better handle errors
      for (const code of changedCodes) {
        const { error: updateError } = await supabase
          .from('tracking_codes')
          .update({
            name: code.name,
            code: code.code,
            is_active: code.is_active,
            location: code.location
          })
          .eq('id', code.id);

        if (updateError) {
          throw updateError;
        }
      }

      await fetchTrackingCodes();
    } catch (error) {
      console.error('Error saving tracking codes:', error);
      setError('Failed to save changes. Please ensure you have admin privileges.');
    } finally {
      setSaving(false);
    }
  }

  function handleCodeChange(id: string, updates: Partial<TrackingCode>) {
    setEditedCodes(current =>
      current.map(code =>
        code.id === id
          ? { ...code, ...updates }
          : code
      )
    );
  }

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#8CC63F] mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading tracking codes...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => navigate('/admin')}
          className="inline-flex items-center text-[#8CC63F] hover:text-[#7AB32E]"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </button>

        <button
          onClick={handleSaveAll}
          disabled={saving}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#8CC63F] hover:bg-[#7AB32E] disabled:opacity-50"
        >
          <Save className="h-4 w-4 mr-2" />
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 sm:p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Tracking Code Management</h1>

          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
              {error}
            </div>
          )}

          <div className="space-y-8">
            {editedCodes.map((code) => (
              <div key={code.id} className="border-b border-gray-200 pb-8 last:border-b-0">
                <div className="flex justify-between items-start mb-4">
                  <h2 className="text-lg font-medium text-gray-900">{code.name}</h2>
                  <div className="flex items-center">
                    <label className="mr-4">
                      <input
                        type="checkbox"
                        checked={code.is_active}
                        onChange={(e) => handleCodeChange(code.id, { is_active: e.target.checked })}
                        className="rounded border-gray-300 text-[#8CC63F] shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      />
                      <span className="ml-2 text-sm text-gray-600">Active</span>
                    </label>
                    <select
                      value={code.location}
                      onChange={(e) => handleCodeChange(code.id, { location: e.target.value as 'head' | 'body' })}
                      className="rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F] text-sm"
                    >
                      <option value="head">Head</option>
                      <option value="body">Body</option>
                    </select>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tracking Code
                  </label>
                  <textarea
                    value={code.code}
                    onChange={(e) => handleCodeChange(code.id, { code: e.target.value })}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F] font-mono text-sm"
                    placeholder="<!-- Paste your tracking code here -->"
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Last updated: {new Date(code.updated_at).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}

            {editedCodes.length === 0 && (
              <div className="text-center py-6 text-gray-500">
                No tracking codes found. Contact your administrator to add tracking codes.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}