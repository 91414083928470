import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const healthConcerns = [
  'Energy & Fatigue',
  'Sleep Quality',
  'Stress & Anxiety',
  'Immune Support',
  'Joint Health',
  'Digestive Health',
  'Heart Health',
  'Brain Function',
  'Weight Management',
  'Athletic Performance',
  'Skin Health',
  'Bone Health',
  'Vision Health',
  'Hormone Balance',
  'Antioxidant Support'
];

export function Questionnaire() {
  const navigate = useNavigate();
  const [selectedConcerns, setSelectedConcerns] = useState<string[]>([]);

  const handleSubmit = () => {
    // Navigate to recommendations with selected concerns as URL parameters
    const params = new URLSearchParams();
    selectedConcerns.forEach(concern => params.append('concerns', concern));
    navigate(`/recommendations?${params.toString()}`);
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Health Assessment</h1>
        <p className="mt-2 text-gray-600">
          Select your health concerns below to receive personalized supplement recommendations.
        </p>
      </div>

      <div className="bg-white shadow rounded-lg p-8">
        <h2 className="text-xl font-semibold mb-4">What are your health goals?</h2>
        <p className="text-gray-600 mb-4">Select all that apply:</p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {healthConcerns.map((concern) => (
            <label key={concern} className="flex items-center space-x-3 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors">
              <input
                type="checkbox"
                checked={selectedConcerns.includes(concern)}
                onChange={(e) => {
                  setSelectedConcerns(e.target.checked
                    ? [...selectedConcerns, concern]
                    : selectedConcerns.filter(c => c !== concern)
                  );
                }}
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <span className="text-gray-700">{concern}</span>
            </label>
          ))}
        </div>

        <div className="mt-8 flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={selectedConcerns.length === 0}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Get Recommendations
            <ChevronRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}