// Simple admin credentials - In a real app, these would be stored securely
export const ADMIN_CREDENTIALS = {
  username: 'admin',
  password: 'admin'
};

export function isAuthenticated(): boolean {
  return localStorage.getItem('isAdminAuthenticated') === 'true';
}

export function login(username: string, password: string): boolean {
  if (username === ADMIN_CREDENTIALS.username && password === ADMIN_CREDENTIALS.password) {
    localStorage.setItem('isAdminAuthenticated', 'true');
    return true;
  }
  return false;
}

export function logout(): void {
  localStorage.removeItem('isAdminAuthenticated');
}