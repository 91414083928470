import { Supplement } from './types';

// Local storage keys
const SUPPLEMENTS_KEY = 'supplements_data';

// Initialize storage with fallback data if empty
function initializeStorage() {
  const stored = localStorage.getItem(SUPPLEMENTS_KEY);
  if (!stored) {
    localStorage.setItem(SUPPLEMENTS_KEY, JSON.stringify(fallbackSupplements));
  }
}

// Get all supplements
export function getSupplements(): Supplement[] {
  initializeStorage();
  const stored = localStorage.getItem(SUPPLEMENTS_KEY);
  return stored ? JSON.parse(stored) : [];
}

// Enhanced getSupplementsByBenefits function
export function getSupplementsByBenefits(concerns: string[]): Supplement[] {
  const supplements = getSupplements();
  
  // Normalize concerns for comparison
  const normalizedConcerns = concerns.map(c => c.toLowerCase());
  
  // Enhanced filter with better brain function matching
  return supplements
    .filter(supplement => 
      supplement.is_active && 
      supplement.benefits.some(benefit => {
        const normalizedBenefit = benefit.toLowerCase();
        return normalizedConcerns.some(concern => {
          // Special handling for brain-related terms
          if (concern.includes('brain') || normalizedBenefit.includes('brain')) {
            return ['brain', 'cognitive', 'memory', 'mental'].some(term =>
              normalizedBenefit.includes(term) || concern.includes(term)
            );
          }
          // Standard matching for other terms
          return normalizedBenefit.includes(concern) ||
                 concern.includes(normalizedBenefit);
        });
      })
    )
    .sort((a, b) => a.name.localeCompare(b.name));
}

// Add or update a supplement
export function saveSupplement(supplement: Supplement): void {
  const supplements = getSupplements();
  const index = supplements.findIndex(s => s.id === supplement.id);
  
  if (index >= 0) {
    supplements[index] = supplement;
  } else {
    supplement.id = crypto.randomUUID();
    supplements.push(supplement);
  }
  
  localStorage.setItem(SUPPLEMENTS_KEY, JSON.stringify(supplements));
}

// Delete a supplement
export function deleteSupplement(id: string): void {
  const supplements = getSupplements();
  const filtered = supplements.filter(s => s.id !== id);
  localStorage.setItem(SUPPLEMENTS_KEY, JSON.stringify(filtered));
}

// Get a single supplement by ID
export function getSupplementById(id: string): Supplement | null {
  const supplements = getSupplements();
  return supplements.find(s => s.id === id) || null;
}

// Fallback data
const fallbackSupplements = [
  {
    id: '1',
    name: 'Vitamin D3',
    description: 'Essential fat-soluble vitamin crucial for bone health, immune function, and overall wellness.',
    benefits: ['Bone Health', 'Immune Support', 'Mood Enhancement', 'Sleep Quality'],
    evidence_rating: 5,
    dosage_info: {
      recommended: '1000-4000 IU daily',
      maximum: '4000 IU daily',
      timing: 'With meals containing healthy fats'
    },
    price_range: {
      min: 10,
      max: 30,
      currency: '$'
    },
    contraindications: ['High blood calcium', 'Kidney stones', 'Certain heart problems'],
    is_active: true
  },
  {
    id: '2',
    name: 'Magnesium Glycinate',
    description: 'Highly bioavailable form of magnesium that supports relaxation, sleep, and muscle function.',
    benefits: ['Sleep Quality', 'Muscle Recovery', 'Stress Relief', 'Bone Health'],
    evidence_rating: 4,
    dosage_info: {
      recommended: '200-400mg daily',
      maximum: '400mg daily',
      timing: 'Evening or divided doses'
    },
    price_range: {
      min: 15,
      max: 40,
      currency: '$'
    },
    contraindications: ['Kidney problems', 'Myasthenia gravis'],
    is_active: true
  }
];