import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Star, Shield, Heart } from 'lucide-react';

export function Home() {
  return (
    <div className="space-y-16">
      <section className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 sm:text-6xl">
          Find the Right Supplements for Your Health Goals
        </h1>
        <p className="mt-6 text-lg text-gray-600 max-w-3xl mx-auto">
          Get personalized supplement recommendations based on your health needs, goals, and preferences. Backed by science, tailored to you.
        </p>
        <div className="mt-8">
          <Link
            to="/questionnaire"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#8CC63F] hover:bg-[#7AB32E]"
          >
            Take the Quiz <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center p-6">
          <div className="mx-auto h-12 w-12 text-[#8CC63F]">
            <Star className="h-full w-full" />
          </div>
          <h3 className="mt-4 text-xl font-medium text-gray-900">Evidence-Based</h3>
          <p className="mt-2 text-gray-500">
            All recommendations backed by scientific research and expert reviews
          </p>
        </div>
        <div className="text-center p-6">
          <div className="mx-auto h-12 w-12 text-[#8CC63F]">
            <Shield className="h-full w-full" />
          </div>
          <h3 className="mt-4 text-xl font-medium text-gray-900">Safe & Reliable</h3>
          <p className="mt-2 text-gray-500">
            Carefully screened supplements from trusted manufacturers
          </p>
        </div>
        <div className="text-center p-6">
          <div className="mx-auto h-12 w-12 text-[#8CC63F]">
            <Heart className="h-full w-full" />
          </div>
          <h3 className="mt-4 text-xl font-medium text-gray-900">Personalized</h3>
          <p className="mt-2 text-gray-500">
            Tailored recommendations based on your unique health profile
          </p>
        </div>
      </section>

      <section className="bg-gray-50 -mx-4 sm:-mx-6 lg:-mx-8 px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900">How It Works</h2>
          <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-3">
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#8CC63F] text-white mx-auto">
                1
              </div>
              <h3 className="mt-4 text-xl font-medium text-gray-900">Take the Quiz</h3>
              <p className="mt-2 text-gray-500">
                Answer questions about your health goals and preferences
              </p>
            </div>
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#8CC63F] text-white mx-auto">
                2
              </div>
              <h3 className="mt-4 text-xl font-medium text-gray-900">Get Recommendations</h3>
              <p className="mt-2 text-gray-500">
                Receive personalized supplement suggestions
              </p>
            </div>
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#8CC63F] text-white mx-auto">
                3
              </div>
              <h3 className="mt-4 text-xl font-medium text-gray-900">Track Progress</h3>
              <p className="mt-2 text-gray-500">
                Monitor your results and adjust recommendations
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}