import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Star, Filter, ExternalLink } from 'lucide-react';
import { supabase } from '../lib/supabase';

type Supplement = {
  id: string;
  name: string;
  description: string;
  benefits: string[];
  evidence_rating: number;
  dosage_info: {
    recommended: string;
    maximum: string;
    timing: string;
  };
  price_range: {
    min: number;
    max: number;
    currency: string;
  };
  contraindications: string[];
  ingredients?: {
    name: string;
    amount: string;
    unit: string;
  }[];
  amazon_link?: string;
};

export function SupplementCatalog() {
  const navigate = useNavigate();
  const [supplements, setSupplements] = useState<Supplement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBenefit, setSelectedBenefit] = useState<string>('');
  const [sortBy, setSortBy] = useState<'name' | 'rating' | 'price'>('name');

  useEffect(() => {
    fetchSupplements();
  }, []);

  async function fetchSupplements() {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('supplements')
        .select('*')
        .order('name');

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      setSupplements(data || []);
    } catch (error) {
      console.error('Error fetching supplements:', error);
      setError('Unable to load supplements. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  const filteredSupplements = supplements
    .filter(supplement => 
      supplement.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supplement.description.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(supplement => 
      !selectedBenefit || supplement.benefits.includes(selectedBenefit)
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'price':
          return a.price_range.min - b.price_range.min;
        case 'rating':
          return b.evidence_rating - a.evidence_rating;
        default:
          return a.name.localeCompare(b.name);
      }
    });

  const allBenefits = Array.from(
    new Set(supplements.flatMap(s => s.benefits))
  ).sort();

  return (
    <div className="max-w-7xl mx-auto">
      <div className="mb-8">
        <div className="flex items-baseline justify-between">
          <h1 className="text-3xl font-bold text-gray-900">Supplement Catalog</h1>
          <p className="text-lg text-gray-600">
            {supplements.length} supplements analyzed
          </p>
        </div>
        <p className="mt-2 text-gray-600">
          Explore our comprehensive database of supplements and their benefits
        </p>
      </div>

      {error && (
        <div className="mb-8 bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
          {error}
        </div>
      )}

      <div className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-3">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search supplements..."
            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#8CC63F] focus:border-[#8CC63F]"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="relative">
          <Filter className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <select
            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#8CC63F] focus:border-[#8CC63F]"
            value={selectedBenefit}
            onChange={(e) => setSelectedBenefit(e.target.value)}
          >
            <option value="">All Benefits</option>
            {allBenefits.map(benefit => (
              <option key={benefit} value={benefit}>{benefit}</option>
            ))}
          </select>
        </div>

        <div>
          <select
            className="px-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#8CC63F] focus:border-[#8CC63F]"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as 'name' | 'rating' | 'price')}
          >
            <option value="name">Sort by Name</option>
            <option value="rating">Sort by Rating</option>
            <option value="price">Sort by Price</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#8CC63F] mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading supplements...</p>
        </div>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Rating
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Benefits
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Dosage
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price Range
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Buy
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredSupplements.map((supplement) => (
                  <tr 
                    key={supplement.id} 
                    className="hover:bg-gray-50 cursor-pointer" 
                    onClick={() => navigate(`/supplement/${supplement.id}`)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{supplement.name}</div>
                      <div className="text-sm text-gray-500">{supplement.description}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Star className="h-5 w-5 text-yellow-400 fill-current" />
                        <span className="ml-1 text-sm text-gray-600">{supplement.evidence_rating}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-1">
                        {supplement.benefits.map((benefit, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#8CC63F] text-white"
                          >
                            {benefit}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">{supplement.dosage_info.recommended}</div>
                      <div className="text-xs text-gray-500">{supplement.dosage_info.timing}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {supplement.price_range.currency}{supplement.price_range.min} - {supplement.price_range.currency}{supplement.price_range.max}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      {supplement.amazon_link ? (
                        <a
                          href={supplement.amazon_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#8CC63F] hover:text-[#7AB32E]"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ExternalLink className="h-5 w-5 inline" />
                        </a>
                      ) : (
                        <span className="text-gray-400">N/A</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {!loading && filteredSupplements.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-600">No supplements found matching your criteria.</p>
        </div>
      )}
    </div>
  );
}