import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Plus, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { isAuthenticated } from '../lib/auth';

type Supplement = {
  id?: string;
  name: string;
  description: string;
  benefits: string[];
  evidence_rating: number;
  dosage_info: {
    recommended: string;
    maximum: string;
    timing: string;
  };
  price_range: {
    min: number;
    max: number;
    currency: string;
  };
  contraindications: string[];
  ingredients: {
    name: string;
    amount: string;
    unit: string;
  }[];
  amazon_link?: string;
  image_url?: string;
  is_active: boolean;
};

const emptyForm: Omit<Supplement, 'id'> = {
  name: '',
  description: '',
  benefits: [],
  evidence_rating: 3,
  dosage_info: {
    recommended: '',
    maximum: '',
    timing: '',
  },
  price_range: {
    min: 0,
    max: 0,
    currency: '$',
  },
  contraindications: [],
  ingredients: [],
  amazon_link: '',
  image_url: '',
  is_active: true,
};

export function SupplementForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState<Omit<Supplement, 'id'>>(emptyForm);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newBenefit, setNewBenefit] = useState('');
  const [newContraindication, setNewContraindication] = useState('');
  const [newIngredient, setNewIngredient] = useState({
    name: '',
    amount: '',
    unit: '',
  });

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/profile');
      return;
    }

    if (id) {
      fetchSupplement();
    }
  }, [id, navigate]);

  async function fetchSupplement() {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('supplements')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        setFormData(data);
      }
    } catch (error) {
      console.error('Error fetching supplement:', error);
      setError('Failed to load supplement data');
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);

      if (id) {
        const { error } = await supabase
          .from('supplements')
          .update(formData)
          .eq('id', id);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('supplements')
          .insert([formData]);
        if (error) throw error;
      }

      navigate('/admin');
    } catch (error) {
      console.error('Error saving supplement:', error);
      setError('Failed to save supplement');
    } finally {
      setLoading(false);
    }
  }

  function handleAddBenefit(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && newBenefit.trim()) {
      e.preventDefault();
      setFormData({
        ...formData,
        benefits: [...formData.benefits, newBenefit.trim()],
      });
      setNewBenefit('');
    }
  }

  function handleAddContraindication(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && newContraindication.trim()) {
      e.preventDefault();
      setFormData({
        ...formData,
        contraindications: [...formData.contraindications, newContraindication.trim()],
      });
      setNewContraindication('');
    }
  }

  function handleAddIngredient() {
    if (newIngredient.name && newIngredient.amount && newIngredient.unit) {
      setFormData({
        ...formData,
        ingredients: [...formData.ingredients, { ...newIngredient }],
      });
      setNewIngredient({ name: '', amount: '', unit: '' });
    }
  }

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#8CC63F] mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <button
        onClick={() => navigate('/admin')}
        className="inline-flex items-center text-[#8CC63F] hover:text-[#7AB32E] mb-6"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Dashboard
      </button>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 sm:p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">
            {id ? 'Edit Supplement' : 'Add New Supplement'}
          </h1>

          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Evidence Rating</label>
              <select
                value={formData.evidence_rating}
                onChange={(e) => setFormData({ ...formData, evidence_rating: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
              >
                {[1, 2, 3, 4, 5].map((rating) => (
                  <option key={rating} value={rating}>
                    {rating} Star{rating !== 1 ? 's' : ''}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Benefits</label>
              <div className="mt-1 flex flex-wrap gap-2">
                {formData.benefits.map((benefit, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {benefit}
                    <button
                      type="button"
                      onClick={() => setFormData({
                        ...formData,
                        benefits: formData.benefits.filter((_, i) => i !== index),
                      })}
                      className="ml-1 text-green-600 hover:text-green-500"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </span>
                ))}
                <input
                  type="text"
                  value={newBenefit}
                  onChange={(e) => setNewBenefit(e.target.value)}
                  onKeyPress={handleAddBenefit}
                  placeholder="Type and press Enter to add"
                  className="flex-1 min-w-[200px] rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 mt-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900">Dosage Information</h3>
                <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Recommended</label>
                    <input
                      type="text"
                      value={formData.dosage_info.recommended}
                      onChange={(e) => setFormData({
                        ...formData,
                        dosage_info: { ...formData.dosage_info, recommended: e.target.value }
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Maximum</label>
                    <input
                      type="text"
                      value={formData.dosage_info.maximum}
                      onChange={(e) => setFormData({
                        ...formData,
                        dosage_info: { ...formData.dosage_info, maximum: e.target.value }
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Timing</label>
                    <input
                      type="text"
                      value={formData.dosage_info.timing}
                      onChange={(e) => setFormData({
                        ...formData,
                        dosage_info: { ...formData.dosage_info, timing: e.target.value }
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900">Price Range</h3>
                <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Currency</label>
                    <select
                      value={formData.price_range.currency}
                      onChange={(e) => setFormData({
                        ...formData,
                        price_range: { ...formData.price_range, currency: e.target.value }
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                    >
                      <option value="$">USD ($)</option>
                      <option value="€">EUR (€)</option>
                      <option value="£">GBP (£)</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Minimum Price</label>
                    <input
                      type="number"
                      value={formData.price_range.min}
                      onChange={(e) => setFormData({
                        ...formData,
                        price_range: { ...formData.price_range, min: Number(e.target.value) }
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Maximum Price</label>
                    <input
                      type="number"
                      value={formData.price_range.max}
                      onChange={(e) => setFormData({
                        ...formData,
                        price_range: { ...formData.price_range, max: Number(e.target.value) }
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900">Ingredients</h3>
                <div className="mt-2">
                  {formData.ingredients.map((ingredient, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      <span className="flex-1">{ingredient.name}</span>
                      <span>{ingredient.amount} {ingredient.unit}</span>
                      <button
                        type="button"
                        onClick={() => setFormData({
                          ...formData,
                          ingredients: formData.ingredients.filter((_, i) => i !== index)
                        })}
                        className="text-red-600 hover:text-red-500"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        value={newIngredient.name}
                        onChange={(e) => setNewIngredient({ ...newIngredient, name: e.target.value })}
                        placeholder="Ingredient name"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        value={newIngredient.amount}
                        onChange={(e) => setNewIngredient({ ...newIngredient, amount: e.target.value })}
                        placeholder="Amount"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      />
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        value={newIngredient.unit}
                        onChange={(e) => setNewIngredient({ ...newIngredient, unit: e.target.value })}
                        placeholder="Unit"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      />
                      <button
                        type="button"
                        onClick={handleAddIngredient}
                        className="mt-1 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#8CC63F] hover:bg-[#7AB32E]"
                      >
                        <Plus className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Contraindications</label>
                <div className="mt-1 flex flex-wrap gap-2">
                  {formData.contraindications.map((contraindication, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                    >
                      {contraindication}
                      <button
                        type="button"
                        onClick={() => setFormData({
                          ...formData,
                          contraindications: formData.contraindications.filter((_, i) => i !== index)
                        })}
                        className="ml-1 text-red-600 hover:text-red-500"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </span>
                  ))}
                  <input
                    type="text"
                    value={newContraindication}
                    onChange={(e) => setNewContraindication(e.target.value)}
                    onKeyPress={handleAddContraindication}
                    placeholder="Type and press Enter to add"
                    className="flex-1 min-w-[200px] rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Amazon Affiliate Link</label>
                <input
                  type="url"
                  value={formData.amazon_link || ''}
                  onChange={(e) => setFormData({ ...formData, amazon_link: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                  placeholder="https://amazon.com/..."
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Image URL</label>
                <input
                  type="url"
                  value={formData.image_url || ''}
                  onChange={(e) => setFormData({ ...formData, image_url: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                  placeholder="https://example.com/image.jpg"
                />
              </div>

              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.is_active}
                    onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                    className="rounded border-gray-300 text-[#8CC63F] shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                  />
                  <span className="ml-2 text-sm text-gray-600">Active (visible in catalog)</span>
                </label>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={() => navigate('/admin')}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#8CC63F] hover:bg-[#7AB32E] disabled:opacity-50"
              >
                {loading ? 'Saving...' : id ? 'Save Changes' : 'Add Supplement'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}