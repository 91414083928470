import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Star, AlertTriangle, Clock, DollarSign, Pill, ShieldCheck, ExternalLink } from 'lucide-react';
import { supabase } from '../lib/supabase';

type Supplement = {
  id: string;
  name: string;
  description: string;
  benefits: string[];
  evidence_rating: number;
  dosage_info: {
    recommended: string;
    maximum: string;
    timing: string;
  };
  price_range: {
    min: number;
    max: number;
    currency: string;
  };
  contraindications: string[];
  ingredients?: {
    name: string;
    amount: string;
    unit: string;
  }[];
  amazon_link?: string;
  image_url?: string;
};

export function SupplementDetail() {
  const { id } = useParams();
  const [supplement, setSupplement] = useState<Supplement | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchSupplement() {
      try {
        setLoading(true);
        setError(null);

        const { data, error } = await supabase
          .from('supplements')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setSupplement(data);
      } catch (error) {
        console.error('Error fetching supplement:', error);
        setError('Unable to load supplement details. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    fetchSupplement();
  }, [id]);

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#8CC63F] mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading supplement details...</p>
        </div>
      </div>
    );
  }

  if (error || !supplement) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <p className="text-red-600">{error || 'Supplement not found'}</p>
          <Link to="/catalog" className="mt-4 inline-block text-[#8CC63F] hover:text-[#7AB32E] font-medium">
            ← Back to Catalog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <Link to="/catalog" className="inline-flex items-center text-[#8CC63F] hover:text-[#7AB32E] mb-6">
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Catalog
      </Link>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 sm:p-8">
          <div className="flex flex-col md:flex-row md:items-start md:space-x-6">
            {supplement.image_url && (
              <div className="w-full md:w-1/3 mb-6 md:mb-0">
                <img
                  src={supplement.image_url}
                  alt={supplement.name}
                  className="w-full h-auto rounded-lg shadow-md"
                />
              </div>
            )}
            
            <div className="flex-1">
              <h1 className="text-3xl font-bold text-gray-900 mb-2">{supplement.name}</h1>
              
              <div className="flex items-center mb-4">
                <div className="flex items-center">
                  {[...Array(5)].map((_, index) => (
                    <Star
                      key={index}
                      className={`h-5 w-5 ${
                        index < supplement.evidence_rating
                          ? 'text-yellow-400 fill-current'
                          : 'text-gray-300'
                      }`}
                    />
                  ))}
                </div>
                <span className="ml-2 text-sm text-gray-600">
                  Evidence Rating: {supplement.evidence_rating}/5
                </span>
              </div>

              <p className="text-gray-700 mb-6">{supplement.description}</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900 mb-2 flex items-center">
                      <ShieldCheck className="h-5 w-5 text-[#8CC63F] mr-2" />
                      Benefits
                    </h2>
                    <ul className="space-y-2">
                      {supplement.benefits.map((benefit, index) => (
                        <li key={index} className="flex items-center">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                            {benefit}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold text-gray-900 mb-2 flex items-center">
                      <AlertTriangle className="h-5 w-5 text-[#8CC63F] mr-2" />
                      Contraindications
                    </h2>
                    <ul className="space-y-2">
                      {supplement.contraindications.map((contraindication, index) => (
                        <li key={index} className="flex items-center text-red-600">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100">
                            {contraindication}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900 mb-2 flex items-center">
                      <Pill className="h-5 w-5 text-[#8CC63F] mr-2" />
                      Dosage Information
                    </h2>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <div className="space-y-2">
                        <p className="text-sm">
                          <span className="font-medium">Recommended:</span>{' '}
                          {supplement.dosage_info.recommended}
                        </p>
                        <p className="text-sm">
                          <span className="font-medium">Maximum:</span>{' '}
                          {supplement.dosage_info.maximum}
                        </p>
                        <p className="text-sm">
                          <span className="font-medium">Timing:</span>{' '}
                          {supplement.dosage_info.timing}
                        </p>
                      </div>
                    </div>
                  </div>

                  {supplement.ingredients && (
                    <div>
                      <h2 className="text-lg font-semibold text-gray-900 mb-2 flex items-center">
                        <Clock className="h-5 w-5 text-[#8CC63F] mr-2" />
                        Ingredients
                      </h2>
                      <div className="bg-gray-50 rounded-lg p-4">
                        <ul className="space-y-2">
                          {supplement.ingredients.map((ingredient, index) => (
                            <li key={index} className="text-sm">
                              {ingredient.name}: {ingredient.amount} {ingredient.unit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  <div>
                    <h2 className="text-lg font-semibold text-gray-900 mb-2 flex items-center">
                      <DollarSign className="h-5 w-5 text-[#8CC63F] mr-2" />
                      Price Range
                    </h2>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-lg font-medium text-gray-900">
                        {supplement.price_range.currency}{supplement.price_range.min} - {supplement.price_range.currency}{supplement.price_range.max}
                      </p>
                    </div>
                  </div>

                  {supplement.amazon_link && (
                    <a
                      href={supplement.amazon_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#8CC63F] hover:bg-[#7AB32E]"
                    >
                      Buy on Amazon
                      <ExternalLink className="ml-2 h-5 w-5" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}