import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Star, ExternalLink, AlertTriangle, Clock, Info } from 'lucide-react';
import { fetchSupplementsByBenefits } from '../lib/supabase';
import type { Supplement } from '../lib/types';

export function Recommendations() {
  const [searchParams] = useSearchParams();
  const selectedConcerns = useMemo(() => searchParams.getAll('concerns'), [searchParams]);
  const [supplements, setSupplements] = useState<Supplement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showEvidenceGuide, setShowEvidenceGuide] = useState(false);

  useEffect(() => {
    async function loadSupplements() {
      if (selectedConcerns.length === 0) return;

      try {
        setIsLoading(true);
        setError(null);
        const results = await fetchSupplementsByBenefits(selectedConcerns);
        setSupplements(results);
      } catch (err) {
        console.error('Error loading supplements:', err);
        setError('Failed to load supplements. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }

    loadSupplements();
  }, [selectedConcerns]);

  if (selectedConcerns.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-8">
          <p className="text-red-600">No health concerns selected. Please go back and select your concerns.</p>
          <Link to="/questionnaire" className="mt-4 inline-block text-[#8CC63F] hover:text-[#7AB32E] font-medium">
            ← Back to Questionnaire
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Your Personalized Recommendations</h1>
            <div className="mt-2 flex flex-wrap gap-2">
              {selectedConcerns.map((concern) => (
                <span
                  key={concern}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-[#8CC63F] text-white"
                >
                  {concern}
                </span>
              ))}
            </div>
          </div>
          <button
            onClick={() => setShowEvidenceGuide(!showEvidenceGuide)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Info className="h-5 w-5 mr-2" />
            Evidence Guide
          </button>
        </div>
      </div>

      {error && (
        <div className="mb-8 bg-red-50 border border-red-200 rounded-lg p-4">
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {isLoading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#8CC63F] mx-auto"></div>
          <p className="mt-4 text-gray-600">Finding the best supplements for you...</p>
        </div>
      ) : supplements.length > 0 ? (
        <div className="mx-auto w-full max-w-[80%]">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Supplement
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Benefits
                    </th>
                    <th scope="col" className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Evidence
                    </th>
                    <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Dosage
                    </th>
                    <th scope="col" className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {supplements.map((supplement) => (
                    <tr key={supplement.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4">
                        <div className="flex flex-col">
                          <span className="font-medium text-gray-900">{supplement.name}</span>
                          <span className="text-sm text-gray-500 mt-1">{supplement.description}</span>
                          {supplement.contraindications.length > 0 && (
                            <div className="mt-2 flex items-start">
                              <AlertTriangle className="h-4 w-4 text-amber-500 mt-0.5 mr-1 flex-shrink-0" />
                              <span className="text-xs text-amber-600">
                                {supplement.contraindications.join(', ')}
                              </span>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-wrap gap-1">
                          {supplement.benefits.map((benefit, index) => (
                            <span
                              key={index}
                              className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                                selectedConcerns.some(concern => 
                                  benefit.toLowerCase().includes(concern.toLowerCase()) ||
                                  concern.toLowerCase().includes(benefit.toLowerCase())
                                )
                                  ? 'bg-green-100 text-green-800'
                                  : 'bg-gray-100 text-gray-800'
                              }`}
                            >
                              {benefit}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center justify-center">
                          <Star className="h-5 w-5 text-yellow-400 fill-current" />
                          <span className="ml-1 text-sm text-gray-600">{supplement.evidence_rating}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <Clock className="h-4 w-4 text-gray-400 mr-2" />
                          <div>
                            <div className="text-sm text-gray-900">{supplement.dosage_info.recommended}</div>
                            <div className="text-xs text-gray-500">{supplement.dosage_info.timing}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <span className="text-sm text-gray-900">
                          {supplement.price_range.currency}{supplement.price_range.min} - {supplement.price_range.currency}{supplement.price_range.max}
                        </span>
                        {supplement.amazon_link && (
                          <a
                            href={supplement.amazon_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-2 inline-flex text-[#8CC63F] hover:text-[#7AB32E]"
                          >
                            <ExternalLink className="h-4 w-4" />
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center py-12">
          <p className="text-gray-600">No supplements found matching your health concerns.</p>
          <Link to="/questionnaire" className="mt-4 inline-block text-[#8CC63F] hover:text-[#7AB32E] font-medium">
            ← Back to Questionnaire
          </Link>
        </div>
      )}
    </div>
  );
}