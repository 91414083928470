import React, { useState, useEffect } from 'react';
import { ArrowLeft, Save } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { isAuthenticated } from '../lib/auth';

type ContentSection = {
  id: string;
  section: string;
  title: string;
  content: string;
  link?: string;
  updated_at: string;
};

export function ContentManagement() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [contents, setContents] = useState<ContentSection[]>([]);
  const [editedContents, setEditedContents] = useState<ContentSection[]>([]);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/profile');
      return;
    }
    fetchContent();
  }, [navigate]);

  async function fetchContent() {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('content')
        .select('*')
        .order('section');

      if (error) throw error;
      setContents(data || []);
      setEditedContents(data || []);
    } catch (error) {
      console.error('Error fetching content:', error);
      setError('Failed to load content');
    } finally {
      setLoading(false);
    }
  }

  async function handleSaveAll() {
    try {
      setSaving(true);
      setError(null);

      // Find changed content items
      const changedContents = editedContents.filter(edited => {
        const original = contents.find(c => c.id === edited.id);
        return original && (original.content !== edited.content || original.link !== edited.link);
      });

      if (changedContents.length === 0) {
        return;
      }

      // Update all changed items
      const { error } = await supabase
        .from('content')
        .upsert(changedContents);

      if (error) throw error;

      // Refresh content after save
      await fetchContent();
    } catch (error) {
      console.error('Error saving content:', error);
      setError('Failed to save changes');
    } finally {
      setSaving(false);
    }
  }

  function handleContentChange(id: string, updates: Partial<ContentSection>) {
    setEditedContents(current =>
      current.map(content =>
        content.id === id
          ? { ...content, ...updates }
          : content
      )
    );
  }

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#8CC63F] mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading content...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => navigate('/admin')}
          className="inline-flex items-center text-[#8CC63F] hover:text-[#7AB32E]"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </button>

        <button
          onClick={handleSaveAll}
          disabled={saving}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#8CC63F] hover:bg-[#7AB32E] disabled:opacity-50"
        >
          <Save className="h-4 w-4 mr-2" />
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 sm:p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Content Management</h1>

          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
              {error}
            </div>
          )}

          <div className="space-y-8">
            {editedContents.map((content) => (
              <div key={content.id} className="border-b border-gray-200 pb-8 last:border-b-0">
                <h2 className="text-lg font-medium text-gray-900 mb-4">{content.title}</h2>
                
                <div className="space-y-4">
                  {content.section === 'about' ? (
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Content</label>
                      <textarea
                        value={content.content}
                        onChange={(e) => handleContentChange(content.id, { content: e.target.value })}
                        rows={4}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Link Text</label>
                        <input
                          type="text"
                          value={content.content}
                          onChange={(e) => handleContentChange(content.id, { content: e.target.value })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">URL</label>
                        <input
                          type="url"
                          value={content.link || ''}
                          onChange={(e) => handleContentChange(content.id, { link: e.target.value })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#8CC63F] focus:ring-[#8CC63F]"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}