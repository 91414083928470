import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';
import { getSupplementsByBenefits as getLocalSupplements } from './localStore';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  console.warn('Supabase environment variables are missing. Falling back to local storage.');
}

// Create Supabase client with optimized settings and better error handling
export const supabase = createClient<Database>(
  supabaseUrl || 'https://placeholder.supabase.co',
  supabaseAnonKey || 'placeholder',
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: false
    },
    global: {
      headers: {
        'x-client-info': 'supplements-guide'
      }
    }
  }
);

// Supplement types
export type Supplement = {
  id: string;
  name: string;
  description: string;
  benefits: string[];
  evidence_rating: number;
  dosage_info: {
    recommended: string;
    maximum: string;
    timing: string;
  };
  price_range: {
    min: number;
    max: number;
    currency: string;
  };
  contraindications: string[];
  ingredients?: {
    name: string;
    amount: string;
    unit: string;
  }[];
  amazon_link?: string;
  image_url?: string;
  is_active: boolean;
};

// Expanded brain-related terms mapping
const BRAIN_FUNCTION_TERMS = [
  'brain function',
  'brain health',
  'cognitive',
  'memory',
  'mental clarity',
  'focus',
  'concentration',
  'neurological',
  'neural',
  'brain',
  'cognitive function',
  'mental function',
  'mental performance'
];

// Helper function to normalize text for comparison
function normalizeText(text: string): string {
  return text.toLowerCase().trim();
}

// Enhanced function to check if terms are related
function areTermsRelated(term1: string, term2: string): boolean {
  const norm1 = normalizeText(term1);
  const norm2 = normalizeText(term2);
  
  // Direct match
  if (norm1 === norm2) return true;
  
  // Check if either term is brain-related and matches the other
  if (BRAIN_FUNCTION_TERMS.some(brainTerm => 
      norm1.includes(brainTerm) || norm2.includes(brainTerm))) {
    return BRAIN_FUNCTION_TERMS.some(brainTerm =>
      (norm1.includes(brainTerm) && norm2.includes('brain')) ||
      (norm2.includes(brainTerm) && norm1.includes('brain'))
    );
  }
  
  // Substring match
  if (norm1.includes(norm2) || norm2.includes(norm1)) return true;
  
  // Word-level match
  const words1 = norm1.split(/\s+/);
  const words2 = norm2.split(/\s+/);
  return words1.some(w1 => words2.some(w2 => 
    w1.length > 3 && w2.length > 3 && (w1.includes(w2) || w2.includes(w1))
  ));
}

// Fetch supplements with filtering and sorting
export async function fetchSupplementsByBenefits(benefits: string[]): Promise<Supplement[]> {
  try {
    // First try Supabase
    const { data, error } = await supabase
      .from('supplements')
      .select('*')
      .eq('is_active', true);

    if (error) {
      console.error('Supabase error:', error);
      // Fall back to local store if Supabase fails
      return getLocalSupplements(benefits);
    }

    if (!data || data.length === 0) {
      // Fall back to local store if no data
      return getLocalSupplements(benefits);
    }

    // Score and filter supplements
    const scoredSupplements = data
      .filter(supplement => 
        supplement.benefits.some(benefit =>
          benefits.some(selectedBenefit => areTermsRelated(benefit, selectedBenefit))
        )
      )
      .map(supplement => {
        // Calculate match score based on multiple factors
        let score = 0;
        
        // Score direct benefit matches
        supplement.benefits.forEach(benefit => {
          benefits.forEach(selectedBenefit => {
            if (areTermsRelated(benefit, selectedBenefit)) {
              // Direct match gets highest score
              if (normalizeText(benefit) === normalizeText(selectedBenefit)) {
                score += 10;
              }
              // Partial match gets lower score
              else {
                score += 5;
              }
            }
          });
        });

        // Add evidence rating to score
        score += supplement.evidence_rating;

        return {
          ...supplement,
          score
        };
      })
      .filter(supplement => supplement.score > 0); // Only keep relevant matches

    // Sort by score and remove score property
    return scoredSupplements
      .sort((a, b) => b.score - a.score)
      .map(({ score, ...supplement }) => supplement as Supplement);

  } catch (error) {
    console.error('Error fetching supplements:', error);
    // Fall back to local store on any error
    return getLocalSupplements(benefits);
  }
}