import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { User, BookOpen, Settings, Leaf } from 'lucide-react';
import { supabase } from '../lib/supabase';

type FooterContent = {
  section: string;
  title: string;
  content: string;
  link?: string;
};

type TrackingCode = {
  id: string;
  name: string;
  code: string;
  location: 'head' | 'body';
  is_active: boolean;
};

export function Layout({ children }: { children: React.ReactNode }) {
  const [footerContent, setFooterContent] = useState<FooterContent[]>([]);
  const [trackingCodes, setTrackingCodes] = useState<TrackingCode[]>([]);

  useEffect(() => {
    fetchFooterContent();
    fetchTrackingCodes();
  }, []);

  async function fetchFooterContent() {
    try {
      const { data } = await supabase
        .from('content')
        .select('*')
        .order('section');
      
      if (data) {
        setFooterContent(data);
      }
    } catch (error) {
      console.error('Error fetching footer content:', error);
    }
  }

  async function fetchTrackingCodes() {
    try {
      const { data } = await supabase
        .from('tracking_codes')
        .select('*')
        .eq('is_active', true);

      if (data) {
        setTrackingCodes(data);
        
        // Insert head tracking codes
        const headCodes = data.filter(code => code.location === 'head');
        headCodes.forEach(code => {
          const scriptElement = document.createElement('div');
          scriptElement.innerHTML = code.code;
          document.head.appendChild(scriptElement.firstElementChild as Node);
        });

        // Insert body tracking codes
        const bodyCodes = data.filter(code => code.location === 'body');
        bodyCodes.forEach(code => {
          const scriptElement = document.createElement('div');
          scriptElement.innerHTML = code.code;
          document.body.appendChild(scriptElement.firstElementChild as Node);
        });
      }
    } catch (error) {
      console.error('Error fetching tracking codes:', error);
    }
  }

  const getContentBySection = (section: string) => 
    footerContent.filter(content => content.section === section);

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link to="/" className="flex items-center">
                <Leaf className="h-8 w-8 text-[#8CC63F]" />
                <span className="ml-2 text-xl font-bold text-gray-900">Healthy Supplements Guide</span>
              </Link>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link to="/questionnaire" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                  Get Recommendations
                </Link>
                <Link to="/catalog" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-900">
                  Supplement Catalog
                </Link>
              </div>
            </div>
            <div className="flex items-center">
              <Link to="/profile" className="p-2 text-gray-500 hover:text-gray-900">
                <User className="h-6 w-6" />
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {children}
      </main>

      <footer className="bg-white mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">About</h3>
              {getContentBySection('about').map(content => (
                <p key={content.id} className="mt-4 text-base text-gray-500">
                  {content.content}
                </p>
              ))}
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Resources</h3>
              <ul className="mt-4 space-y-4">
                {getContentBySection('resources').map(content => (
                  <li key={content.id}>
                    <Link to={content.link || '#'} className="text-base text-gray-500 hover:text-gray-900">
                      {content.content}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
              <ul className="mt-4 space-y-4">
                {getContentBySection('legal').map(content => (
                  <li key={content.id}>
                    <Link to={content.link || '#'} className="text-base text-gray-500 hover:text-gray-900">
                      {content.content}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 text-center">
              © {new Date().getFullYear()} Healthy Supplements Guide. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}