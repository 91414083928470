import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Questionnaire } from './pages/Questionnaire';
import { Recommendations } from './pages/Recommendations';
import { SupplementCatalog } from './pages/SupplementCatalog';
import { SupplementDetail } from './pages/SupplementDetail';
import { AdminDashboard } from './pages/AdminDashboard';
import { SupplementForm } from './pages/SupplementForm';
import { ContentManagement } from './pages/ContentManagement';
import { TrackingCodeManagement } from './pages/TrackingCodeManagement';
import { Profile } from './pages/Profile';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/catalog" element={<SupplementCatalog />} />
          <Route path="/supplement/:id" element={<SupplementDetail />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/add" element={<SupplementForm />} />
          <Route path="/admin/edit/:id" element={<SupplementForm />} />
          <Route path="/admin/content" element={<ContentManagement />} />
          <Route path="/admin/tracking" element={<TrackingCodeManagement />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;